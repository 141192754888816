import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/.app/middleware/auth.global.ts";
import set_45tenant_45global from "/vercel/path0/stagedoos/middleware/set_tenant.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  set_45tenant_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}