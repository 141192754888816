import revive_payload_client_td6lX4M2rK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Frj29uWHh7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HeJIHp9Xpb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fedtrmgS9z from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vYxoxs1XCI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_MB2zBVNbog from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NT8KAQGg6x from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yVfo3eeQ6m from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.5.12_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/stagedoos/.nuxt/components.plugin.mjs";
import prefetch_client_CxCEDwOrvX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_cz6housart5fxn4zm6mln6ma2e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_0Jo45oZoCc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_S6s4iEcOql from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.7.2__webpack-sources@3.2.3/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_oS1oT0aPIL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_1Xrh5HMXIo from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.6_patch_hash=zfnymi2b2itc6bxqlfbjljgqim_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_fl8iquJV2R from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.7.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_5ajgOoGadW from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.5.12_typescr_pw7va6a4t77sj5jlm5pfi2l2iu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import default_locale_U9KBtXyBIy from "/vercel/path0/.app/plugins/default-locale.ts";
import dompurify_CKiVSAlvqt from "/vercel/path0/.app/plugins/dompurify.ts";
import yup_1KuivxImeD from "/vercel/path0/.app/plugins/yup.ts";
import vue3_signature_nftuGzYaMt from "/vercel/path0/stagedoos/plugins/vue3-signature.ts";
export default [
  revive_payload_client_td6lX4M2rK,
  unhead_Frj29uWHh7,
  router_HeJIHp9Xpb,
  payload_client_fedtrmgS9z,
  navigation_repaint_client_vYxoxs1XCI,
  check_outdated_build_client_MB2zBVNbog,
  chunk_reload_client_NT8KAQGg6x,
  plugin_vue3_yVfo3eeQ6m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CxCEDwOrvX,
  plugin_client_0Jo45oZoCc,
  plugin_S6s4iEcOql,
  plugin_oS1oT0aPIL,
  plugin_client_1Xrh5HMXIo,
  i18n_fl8iquJV2R,
  plugin_5ajgOoGadW,
  directives_yBPzJQtdia,
  default_locale_U9KBtXyBIy,
  dompurify_CKiVSAlvqt,
  yup_1KuivxImeD,
  vue3_signature_nftuGzYaMt
]