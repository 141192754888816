export default function useTenant() {
  const tenant = useCookie<string>('tenant', {
    secure: true,
    watch: true,
  })

  function setInitialTenant() {
    const host = useRequestURL().host
    const publicConfig = useRuntimeConfig().public
    const tenantsConfig = publicConfig.tenants as Record<string, string>
    const defaultTenant = publicConfig.defaultTenant as string

    // First check if there's an explicit tenant configuration for this host
    if (tenantsConfig[host]) {
      tenant.value = tenantsConfig[host]
      return
    }

    // Then check if this is a stageplein.nl subdomain
    if (host.endsWith('.stageplein.nl')) {
      const subdomain = host.split('.')[0]
      if (subdomain) {
        tenant.value = subdomain
        return
      }
    }

    // Fall back to default tenant if no match found
    tenant.value = defaultTenant
  }

  if (!tenant.value) setInitialTenant() // disabled due to issue on mas4you launch, can be re-enabled later
  // console.log('useTenant', tenant.value)

  const tenantConfig = computed(() => {
    const traineeshipTenants = ['match2gether', 'axia', 'raayland']
    const isMas = !traineeshipTenants.includes(tenant.value)
    const isTraineeship = !isMas

    return {
      // Is this a mas tenant?
      isMas: isMas,
      // Is this a traineeship tenant?
      isTraineeship: isTraineeship,
      // Are we using evaluation forms?
      isUsingEvaluationForms: isMas && tenant.value == 'corderius',
      // Are we using printable paper timesheets?
      isUsingPaperTimesheets: isMas && tenant.value == 'mas4you',
      // Is using digital contracts
      isUsingDigitalContracts: isTraineeship,
    }
  })

  return { tenant, tenantConfig }
}
